import base from '../base';

export async function getProducts() {
    return await base.get(
        '/all-products'
    )
}
export async function postProduct (productData) {
    return await base.post(
        '/new-product',
        productData
    )
}
export async function getProduct(product_sku) {
    return await base.get(
        `/getProduct/${product_sku}`
    )
}
export async function onLogin(loginData) {
    return await base.post(
        '/login',
        loginData
    )
}

