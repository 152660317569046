import React, { useState } from 'react'
import { Layout } from '../components/layout'
import styles from './pages.module.css'
import ReCAPTCHA from "react-google-recaptcha";
import { onRegistration } from '../apis/auth/auth';
import { onEmailSubscription } from '../apis/emailList/emailList';
import { Helmet } from "react-helmet";
import Rounder from '../components/fixtureSet';
import DraggableElement from '../components/draggableElement';
import backgroundMap from '../img/map.svg'
import { SvgMapFunction } from '../components/svgMap';
import ProductList from './productList';




const Home = () => {
    const [values, setValues] = useState({ email: '', password: '' });
    const [email, setEmail] = useState({ email: '' });
    const [recaptcha, setRecaptcha] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error1, setError1] = useState(false);


    function onReCAPTCHA(value) {
        // console.log("Captcha value:", value);
        setRecaptcha(true);
    }

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value })
        // styles.recaptcha = { dispaly: 'block' }
    }
    const onEmailChange = (e) => {
        setEmail({ ...email, [e.target.name]: e.target.value });
        // styles.recaptcha = { dispaly: 'block' }
    }
    const onEmailSubmit = async (e) => {
        e.preventDefault()
        document.getElementById('email').value = '';
        document.getElementById('submit').disabled = 'true';
        try {
            const { data } = await onEmailSubscription(email)
            // const response = await onRegistration(values)
            // console.log(response);
            setError1('')
            setSuccess(data.message)
            setEmail('')
            setTimeout(() => {
                setSuccess(false)
            }, "5000");

        } catch (error) {
            // console.log(error);
            // console.log(error.response.data.errors[0].msg);
            if (error.response.data.errors[0].msg) {
                setError1(error.response.data.errors[0].msg)
                setTimeout(() => {
                    setError1(false)
                }, "5000");
            }
            // // if (error.response.data.errors[1].msg !== undefined) {
            //     setError1(error.response.data.errors[0].msg)
            // // }
            setSuccess('')
        }

    }
    return (
        <Layout>
            <div className={styles.page}>
                <Helmet>
                    <title>home</title>
                </Helmet>
                {/* <Rounder/> */}
                <div className={styles.mapContanier}>
                    {/* <SvgMapFunction /> */}
                    < ProductList />
                </div>

            </div>

        </Layout>
    )
}

export default Home