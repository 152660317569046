import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styles from './components.module.css';
import logov3 from '../img/logov3.png'
import { useDispatch } from 'react-redux'
import { onLogout } from '../apis/auth/auth';
import { unauthenticateUser } from '../redux/slices/authSlice';


const Navbar = () => {
    // const authState = useSelector((state) => state.auth);
    const { isAuth } = useSelector((state) => state.auth)
    const dispatch = useDispatch()
    const logout = async () => {
        try {
          await onLogout();
    
          dispatch(unauthenticateUser());
          localStorage.removeItem('isAuth');
        } catch (error) {
        //   console.log(error.response)
        }
      }
    return (
        <>
            <nav className={styles.navbar}>
                <div className={styles.container}>
                    <div className={styles.left}>
                        <Link to='/'>
                            <span className={styles.logo}>Home</span>
                        </Link>
                    </div>

                    {isAuth ? (
                        <div className={styles.right}>
                            <Link to='/search' className={styles.link}>
                                <span>SEARCH</span>
                            </Link>
                            {/* <Link to='/orders' className={styles.link}>
                                <span>ORDERS</span>
                            </Link> */}
                            {/* <Link to='/products' className={styles.link}>
                                <span>PRODUCTS</span>
                            </Link> */}
                            {/* <Link to='/edit-pages' className={styles.link}>
                                <span>EDIT PAGES</span>
                            </Link> */}
                            {/* <a className={styles.link}>
                                <span>MENU</span>
                            </a> */}
                            <a onClick={() => logout()} className={styles.link}>
                                <span>LOGOUT</span>
                            </a>
                        </div>
                    ) : (
                        <div className={styles.right}>
                            {/* <Link to='/' className={styles.link}>
                                <span>MENU</span>
                            </Link>
                            <Link to='/search' className={styles.link}>
                                <span>SEARCH</span>
                            </Link> */}
                            <a className={styles.link}>
                                <span>MENU</span>
                            </a>
                            {/* <a className={styles.link}>
                                <span>SEARCH</span>
                            </a> */}
                            {/* <Link to='/register' className={styles.link}>
                                <span>Register</span>
                            </Link> */}
                        </div>
                    )}
                </div>
            </nav>
        </>
    )
}

export default Navbar