import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
// import StarRating from './StarRating';
import { getProducts } from '../apis/productLocations/productLocations';

const ProductList = () => {
    const [products, setProducts] = useState([]);


    let navigate = useNavigate();



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getProducts();
                console.log(response);
                // console.log(response.data.data.products);
                setProducts(response.data.data);
            } catch (err) {
                console.log(err);
            }
        };
        fetchData();
    }, [setProducts]);

    const handleDelete = async (e, id) => {
        e.stopPropagation();
        try {
            // await ProductFinder.delete(`/${id}`);
        } catch (err) {
            console.log(err);
        }
    };
    const handleUpdate = (e, id) => {
        e.stopPropagation();
        navigate(`/products/${id}/update`);
    };

    const handleProductSelect = (id) => {
        navigate(`/products/${id}`);
    };

    const renderRating = (product) => {
        if (!product.count) {
            return (
                <>
                    <span className="text-warning ml-1">(0)</span>
                </>
            )
        }
        return (
            <>
                <span className="text-warning ml-1">({product.count})</span>
            </>
        )
    }

    return (
        <div className='list list-group'>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope='col'>Product DB ID</th>
                        <th scope='col'>Product (Item Number)</th>
                        <th scope='col'>Product Locations</th>
                    </tr>
                </thead>
                <tbody className='table-dark'>
                    {products && products.map((product) => {
                        return (
                            <tr onClick={() => handleProductSelect(product.id)} key={product.id}>
                                <td>{product.product_id}</td>
                                <td>{product.product_sku}</td>
                                <td>{product.product_locations.join(", ")}.</td>
                                <td>
                                    <button onClick={(e) => handleUpdate(e, product.id)} className='btn btn-warning'>Update</button>
                                </td>
                                <td>
                                    <button onClick={(e) => handleDelete(e, product.id)} className='btn btn-danger'>Delete</button>
                                </td>
                            </tr>
                        );
                    })}
                    {/* <tbody className='table-dark'>
                    <tr>
                        <td>mcdonalds</td>
                        <td>New York</td>
                        <td>$$</td>
                        <td>Rating</td>
                        <td><button className='btn btn-warning'>Update</button></td>
                        <td><button className='btn btn-danger'>Delete</button></td>
                    </tr>
                    <tr>
                        <td>mcdonalds</td>
                        <td>New York</td>
                        <td>$$</td>
                        <td>Rating</td>
                        <td><button className='btn btn-warning'>Update</button></td>
                        <td><button className='btn btn-danger'>Delete</button></td>
                    </tr> */}
                </tbody>
            </table>
        </div >
    )
}

export default ProductList