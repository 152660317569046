import React from 'react';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom'
import './App.css';
import Home from './pages/home';
import Login from './pages/login';
import Dashboard from './oldPages/dashboard';
import DataInput from './oldPages/capture';
import Register from './oldPages/register';
import { useSelector } from 'react-redux';
import Privacy from './pages/privacy';
import Terms from './pages/terms';
import Faq from './pages/faq';
import Subscribe from './oldPages/subscribe';
import Capture from './oldPages/capture';
import { Helmet } from "react-helmet";
import SearchPage from './pages/searchPage';
import Orders from './pages/orders';
import Products from './pages/products';
import EditPages from './pages/editPages';


export const PrivateRoutes = () => {
    const authState = useSelector((state) => state.auth);

    return (
        <>{authState.isAuth ? <Outlet /> : <Navigate to='/login' />}</>
    )
}
export const PublicRoutes = () => {
    const authState = useSelector((state) => state.auth);

    return (
        <>{!authState.isAuth ? <Outlet /> : <Navigate to='/' />}</>
    )

}

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<PrivateRoutes />} >
                    <Route path='/search' element={<SearchPage />} />
                    {/* <Route path='/orders' element={<Orders />} /> */}
                    <Route path='/products' element={<Products />} />
                    <Route path='/edit-pages' element={<EditPages />} />
                    <Route path='/home' element={<Home />} />
                    <Route path='/' element={<Home />} />
                </Route>
                <Route element={<PublicRoutes />} >
                    <Route path='/login' element={<Login />} />
                </Route>
                {/* <Route path='/privacy' element={<Privacy />} /> */}
                {/* <Route path='/terms' element={<Terms />} /> */}
                {/* <Route path='/faq' element={<Faq />} /> */}

            </Routes>
        </BrowserRouter>
    );
}

export default App;
