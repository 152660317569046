import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fetchProtectedInfo, onLogout } from '../apis/auth/auth'
import { Layout } from '../components/layout'
import { unauthenticateUser } from '../redux/slices/authSlice'
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from 'react-router-dom'
import styles from './pages.module.css'
import {Helmet} from "react-helmet";
import { getProduct } from '../apis/productLocations/productLocations'



const SearchPage = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [protectedData, setProtectedData] = useState(null)

    const logout = async () => {
        try {
            await onLogout();

            dispatch(unauthenticateUser());
            localStorage.removeItem('isAuth');
        } catch (error) {
            //   console.log(error.response)
        }
    }

    const protectedInfo = async () => {
        try {
            const { data } = await fetchProtectedInfo();

            setProtectedData(data.info);

            setLoading(false);
        } catch (error) {
            logout();
        }
    }

    // useEffect(() => {
    //     protectedInfo();
    // })
    const [values, setValues] = useState({ email: '', password: '' });
    const [products, setProducts] = useState([]);
    const [search, setSearch] = useState('');
    const [type, setType] = useState('idea');
    const [error1, setError1] = useState(false);
    const [error2, setError2] = useState(false);
    const [success, setSuccess] = useState(false);
    const [recaptcha, setRecaptcha] = useState(false);


    function onReCAPTCHA(value) {
        // console.log("Captcha value:", value);
        setRecaptcha(true);
    }

    const onChange = (e) => {
        setProducts()
    }
    const onSubmit = async (e) => {
        e.preventDefault()

        try {
            const { data } = await getProduct(search)
            // const response = await onRegistration(values)
            // console.log(response);
            setError1('')
            setError2('')
            setSuccess(data.message)
            setProducts(data.data)
            setTimeout(() => {
                
                setSuccess(false)
            }, "5000");

        } catch (error) {
            // console.log(error);
            // console.log(error.response.data.errors[0].msg);
            // if (error.response.data.errors[0].msg) {
            //     setError1(error.response.data.errors[0].msg)
            //     setTimeout(() => {
            //         setError1(false)
            //     }, "5000");
            // }
            // // // if (error.response.data.errors[1].msg !== undefined) {
            // //     setError1(error.response.data.errors[0].msg)
            // // // }
            // setSuccess('')
        }

    }

    return loading ? (
        <Layout>
            <h1>Loading...</h1>
        </Layout>
    ) : (
        <div>
            <Layout>
                <div className={styles.page}>
                    <h1>Search</h1>

                    {/* <button onClick={() => logout()} className={styles.submitBtn}>
                        Logout
                    </button> */}
                    <form onSubmit={(e) => onSubmit(e)} id='search' className={styles.form}>
                        <div className={styles.formInput}>
                            <label htmlFor='search' className={styles.formLabel}>
                                Search
                            </label>
                            <input
                                onChange={(e) => setSearch(e.target.value)}
                                type='text'
                                className={styles.formControl}
                                id='search'
                                name='search'
                                value={search}
                                placeholder=''
                                required
                            />

                        </div>


                        <div className={styles.formSubmit}>

                            <button type='submit'
                                className={styles.submitBtn}
                                disabled={!recaptcha}
                            // onClick={onSubmit1()}
                            >
                                Submit
                            </button>
                        </div>
                        <div>RESULTS:</div>
                        <p>{protectedData}</p>
                        <div className='list list-group'>
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th scope='col'>Product DB ID</th>
                        <th scope='col'>Product (Item Number)</th>
                        <th scope='col'>Product Locations</th>
                    </tr>
                </thead>
                <tbody className='table-dark'>
                    {products && products.map((product) => {
                        return (
                            <tr 
                            // onClick={() => handleProductSelect(product.id)}
                             key={product.id}>
                                <td>{product.product_id}</td>
                                <td>{product.product_sku}</td>
                                <td>{product.product_locations.join(", ")}.</td>
                                <td>
                                    <button
                                    //  onClick={(e) => handleUpdate(e, product.id)} 
                                    className='btn btn-warning'>Update</button>
                                </td>
                                <td>
                                    <button 
                                    // onClick={(e) => handleDelete(e, product.id)} 
                                    className='btn btn-danger'>Delete</button>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div >

                        {success ? <div style={{ color: 'green', margin: '10px 0' }}>{success}</div> : ''}
                        {error1 ? <div style={{ color: 'red', margin: '10px 0' }}>{error1}</div> : ''}
                        {/* {error2 ? <div style={{ color: 'red', margin: '10px 0' }}>{error2}</div> : ''} */}
                    </form>
                </div>
            </Layout>
        </div>
    )
}

export default SearchPage